<template>
    <dashboard-layout>
        <div slot="main-content">
            <h2 class="dash-title">Pregled</h2>

            
            <section class="recent">
                <div class="">
                    <div class="activity-card">
                        <h3>Nedavno dodati proizvodi</h3>
                        
                        <div class="table-responsive">
                            <table>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Slika</th>
                                        <th>Ime</th>
                                        <th>Kategorija</th>
                                        <th>Cijena</th>
                                        <th>Dostupnost?</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(menuItem, index) in menu" :key="index" >
                                        <td>#{{index + 1}}</td>
                                        <td><img :src="menuItem.image" style="border-radius: 50%" height="50px" width="50px" alt=""></td>
                                        <td>{{menuItem.name}}</td>
                                        <td>{{menuItem.category.name}}</td>
                                        <td>{{menuItem.price}}</td>
                                        <td>
                                            <span class="badge success" v-if="Number(menuItem.isAvailable) === 1">Dostupan</span>
                                            <span class="badge warning" v-else>Nedostupan</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </dashboard-layout>
</template>

<script>
import DashboardLayout from '@/components/Layouts/DashboardLayout'

export default {
    components: {
        DashboardLayout
    },
    name: 'AdminHome',
    data() {
        return {
            menu: []
        }
    },
    mounted() {
        this.getMenu()
    },
    methods: {
        getMenu() {
            this.$axios.get(`${this.$apiUrl}/menu/all`)
            .then(res => {
                this.menu = res.data.data.slice(0, 3)
            })
            .catch(error => {
                console.log(error.response)
            })
        },
    }
}
</script>