<template>
    <div class="account-form">
        <h3>Prijavite se na vas nalog</h3>
        <div class="form-group">
            <label for="">Email</label>
            <input type="text" v-model="data.email" class="form-control" placeholder="example@gmail.com" />
        </div>

        <div class="form-group">
            <label for="">Lozinka</label>
            <input type="password" v-model="data.password" class="form-control" placeholder="**********" />
        </div>

        <div class="form-group">
            <button class="btn btn-main-gradient btn-block" @click="$emit('eventact', {eventType: 'signin'})">Prijava</button>
        </div>
    </div>
</template>

<script>
export default {
    props: ['data'],
    name: 'Login'
}
</script>