<template>
    <dashboard-layout>
        <div slot="main-content">
            <h2 class="dash-title">Dodaj korisnika</h2>
            
            <section class="recent">
                <div class="">
                    <div class="activity-card pad-1">
                        <form enctype="multipart/form-data">
                            <div class="form-group">
                                <label for="">Ime i prezime</label>
                                <input type="text" v-model="user.name" class="form-control" placeholder="Ime korisnika">
                            </div>

                            <div class="form-group">
                                <label for="">Email</label>
                                <input type="text" v-model="user.email" class="form-control" placeholder="Email korisnika">
                            </div>

                            <div class="form-group">
                                <label for="">Uloga</label>
                                <select v-model="user.role" class="form-control">
                                    <option value="">Izbor</option>
                                    <option value="admin">Administrator</option>
                                    <option value="customer">Kupac</option>
                                    <option value="driver">Dostavljac</option>
                                </select>
                            </div>

                            <div class="form-group">
                                <label for="">Lozinka</label>
                                <input type="password" v-model="user.password" class="form-control" placeholder="*********">
                            </div>
                            

                            <div class="form-group">
                                <button type="button" @click="addUser" class="btn btn-main">Dodaj</button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </div>
    </dashboard-layout>
</template>

<script>
import DashboardLayout from '@/components/Layouts/DashboardLayout'

export default {
    name: 'AddMenu',
    components: {
        DashboardLayout,
    },
    data() {
        return {
            categories: [],
            user: {
                name: '',
                email: '',
                role: '',
                password: '',
            }
        }
    },
    mounted() {
        
    },
    methods: {
        addUser() {
            const {name, email, role, password} = this.user
            
            if(!name || !email || !role || !password) {
                return this.$alertify.error('Incomplete form data')
            }

            this.$axios.post(`${this.$apiUrl}/auth/register`, {name, email, role, password}, {
                headers: {
                    Authorization: `Bearer ${localStorage.authtoken}`
                }
            })
            .then(() => {
                this.$router.push('/admin/users')
            })
            .catch(error => {
                if(error.response.data.message) {
                    return this.$alertify.error(error.response.data.message)
                }
                this.$alertify.error(Object.values(error.response.data)[0][0])
            })
        }
    }
}
</script>

<style lang="css">
    .pad-1 {
        padding: 1rem
    }
</style>