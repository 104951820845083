<template>
    <div class="account-wrapper">
        <div>
            <div>
                <div class="account-header">
                    <svg height="100px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12,3L4,9v12h16V9L12,3z M12.5,12.5c0,0.83-0.67,1.5-1.5,1.5v4h-1v-4c-0.83,0-1.5-0.67-1.5-1.5v-3h1v3H10v-3h1v3h0.5v-3h1 V12.5z M15,18h-1v-3.5h-1v-3c0-1.1,0.9-2,2-2V18z"/></svg>
                    <h3>Sicilija</h3>
                </div>

                <div class="account-tabs">
                    <button :class="isActive === 0? 'is-active': ''" @click="isActive = 0">Prijava</button>
                    <button :class="isActive === 1? 'is-active': ''" @click="isActive = 1">Registracija</button>
                </div>

                <div class="account-form-wrapper">
                    <component :is="compsArr[isActive]" :data="compsData[isActive]" @eventact="processEvent"></component>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Login from '@/components/Login'
import Register from '@/components/Register'

export default {
    name: 'Account',
    data() {
        return {
            compsArr: [
                Login,
                Register,
            ],
            isActive: 0,
            compsData: [
                {
                    email: '',
                    password: '',
                },
                {
                    name: '',
                    email: '',
                    password: '',
                    role: '',
                }
            ]
        }
    },
    methods: {
        processEvent(payload) {
            if(payload.eventType === 'signin') {
                this.signin()
            }else if(payload.eventType === 'signup') {
                this.signup()
            }
        },
        signin() {
            const { email, password } = this.compsData[this.isActive]

            if(!email || !password) {
                return this.$alertify.error('Incomplete credentials');
            }

            this.$axios.post(`${this.$apiUrl}/auth/login`,{
                email, 
                password
            })
            .then(res => {
                const data = res.data

                if(data.user.role !== 'admin') {
                    localStorage.token = JSON.stringify(data.token)
                    localStorage.user = JSON.stringify(data.user)
                    this.$store.commit('setUser', data.user)
                    this.$router.push('/')
                }
            })
            .catch(error => {
                this.$alertify.error(error.response.data.message)
            })
        },
        signup() {
            let { role, name, email, password } = this.compsData[this.isActive]

            if(!email || !password || !role || !name) {
                return this.$alertify.error('Incomplete form data');
            }
            
            this.$axios.post(`${this.$apiUrl}/auth/register`, {
                name, 
                email,
                password, 
                role
            })
            .then(res => {
                this.compsData[this.isActive] = {
                    name: '',
                    email: '',
                    password: '',
                    role: '',
                }
                this.$alertify.success(res.data.message)
            })
            .catch(error => {
                this.$alertify.error(error.response.data.message)
            })

        }
    }
}
</script>
